import { Environment } from './environment.model';

export const environment: Environment = {
  name: 'TEST',
  production: false,
  garmin: {
    aviation: {
      coreDataHost: 'https://avc-coredata-test.garmin.com',
      gatewayHost: 'https://avcloud-gateway-test.garmin.com/webGateway',
      servicesHost: 'https://avcloud-api-test.garmin.com',
      workerApiHost: 'https://test.pilotweb.garmin.com/api',
      coreData: {
        aircraftManagementService: {
          read: 'https://avc-coredata-test.garmin.com/aircraftManagementReadService',
          write: 'https://avc-coredata-test.garmin.com/aircraftManagementWriteService',
        },
        customerService: {
          read: 'https://avc-coredata-test.garmin.com/customerReadService',
          write: 'https://avc-coredata-test.garmin.com/customerWriteService',
        },
        avdbService: {
          read: 'https://avc-coredata-test.garmin.com/avdbReadService',
          write: 'https://avc-coredata-test.garmin.com/avdbWriteService',
        },
        subscriptionService: {
          read: 'https://avc-coredata-test.garmin.com/subscriptionReadService',
          write: 'https://avc-coredata-test.garmin.com/subscriptionWriteService',
        },
        deviceService: {
          read: 'https://avc-coredata-test.garmin.com/deviceReadService',
          write: 'https://avc-coredata-test.garmin.com/deviceWriteService',
        },
      },
    },
  },
  // All environments will be pointing to Tealium Prod
  tealiumConfig: {
    account: 'garmin',
    profile: 'main',
    environment: 'prod',
  },
  trustArcConfig: {
    url: 'https://consent.trustarc.com/notice?domain=garmingdpr.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&pcookie',
  },
};
